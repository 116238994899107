import { Col, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Terms from "../Components/Configurations/Terms";
import TestRegistrationSettings from "../Components/Configurations/TestRegistrationSettings";
import {
  getHomeData,
  setSelectedTerm,
  setSelectedYear,
} from "../Redux/Slices/HomeSlice";
export function Configurations() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const homeData = useSelector((state) => state.home);
  useEffect(() => {
    if (homeData.terms.length === 0) {
      fetchHomeData();
    }
    // eslint-disable-next-line
  }, []);
  const fetchHomeData = async () => {
    let response = await dispatch(
      getHomeData({
        token: auth.token,
      })
    );
    if (response.payload) {
      let current = response.payload.details.terms.find((x) => x.current === 1);
      dispatch(setSelectedTerm(current.id));
      dispatch(setSelectedYear(current.year));
    }
  };
  const [selected, setSelected] = useState("Test Registrations");
  function selectItem(item) {
    setSelected(item);
  }
  function getComponent() {
    switch (selected) {
      case "Test Registrations":
        return <TestRegistrationSettings />;
      case "Terms":
        return <Terms />;
      default:
        break;
    }
  }
  const items = [
    {
      label: "Test Registrations",
      key: "Test Registrations",
      style: { margin: 0 },
    },
    { label: "Terms", key: "Terms", style: { margin: 0 } },
  ];
  return (
    <div>
      <Row style={{ marginTop: 64 }} align="center">
        <Col xs={4} style={{ backgroundColor: "white" }}>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={selected}
            items={items}
            onSelect={({ key }) => selectItem(key)}
          />
        </Col>
        <Col xs={16} style={{ backgroundColor: "white", padding: 8, borderLeft:"0.5px solid lightgray"}}>
          {getComponent()}
        </Col>
      </Row>
    </div>
  );
}
