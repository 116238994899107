import axios from 'axios';
import { notification } from 'antd';
import { url } from './Config';
import { logout } from '../Redux/Slices/AuthSlice';

const MakeRequest = (contentType, thunkAPI) => {
    let headers;
    if (contentType) {
        headers = {
            Accept: "application/json",
            'content-type': contentType
        }
    } else {
        headers = {
            Accept: "application/json",
        }
    }
    const Api = axios.create({
        baseURL: url,
        headers: headers
    });
    Api.interceptors.request.use(config => {
        config.withCredentials = true
        return config
    })
    Api.interceptors.response.use(response => {
        if (response.data.success) {
            if (response.data.message) {
                openSuccessNotification(response.data.message)
            }
            return response
        } else if (response.data.responseCode === 301) {
            if (thunkAPI.dispatch) {
                thunkAPI.dispatch(logout())
            } else {
                thunkAPI(logout())
            }
        } else {
            if (response.data.message) {
                openErrorNotification(response.data.message)
            }
        }

    }, error => {
        openErrorNotification("Please check your internet connection and try again")
    })
    return (Api)
}


const openErrorNotification = (message) => {
    const key = `open${Date.now()}`;
    // const btn = (
    //     <Button type="primary" size="small" onClick={() => notification.close(key)}>
    //         Confirm
    //     </Button>
    // );
    notification.error({
        message: 'Error Occured',
        description: message,
        key,
    });
};
const openSuccessNotification = (message) => {
    const key = `open${Date.now()}`;
    // const btn = (
    //     <Button type="primary" size="small" onClick={() => notification.close(key)}>
    //         Confirm
    //     </Button>
    // );
    notification.success({
        message: 'Message',
        description: message,
        key,
    });
};
export default MakeRequest