import { Button, Drawer, Form, Row, Col, Input, Radio } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTerms, setTerms } from "../../../Redux/Slices/DashboardSlice";
import { url } from "../../../Utils/Config";
import MakeRequest from "../../../Utils/MakeRequest";
export default function AddTerm() {
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const sendData = async () => {
    let isValid = await form.validateFields();
    if (isValid) {
      setloading(true);
      let data = form.getFieldsValue();
      var form_data = new FormData();
      for (var key in data) {
        form_data.append(key, data[key]);
      }
      const response = await MakeRequest(null,dispatch).post("/addTerm",form_data)
      if (response) {
        setloading(false);
        setVisible(false);
        form.resetFields();
        dispatch(setTerms(response.data.details));
      } else {
        setloading(false);
      }
    }
  };
  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        Add Term
      </Button>
      <Drawer
        destroyOnClose={true}
        title="Add Term"
        width={window.innerWidth > 900 ? 720 : "100%"}
        onClose={() => setVisible(false)}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => setVisible(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                sendData();
              }}
              type="primary"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            ignore_end_date: false,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="term"
                label="Term Name"
                rules={[{ required: true, message: "Please enter term name" }]}
              >
                <Input placeholder="Please enter term name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="year"
                label="Year"
                rules={[{ required: true, message: "Please enter term year" }]}
              >
                <Input placeholder="Please enter year" type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{ required: true, message: "Please enter start date" }]}
              >
                <Input placeholder="Please enter start date" type="date" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[{ required: true, message: "Please enter end date" }]}
              >
                <Input placeholder="Please enter end date" type="date" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="select_school"
                label="Select School ?"
                rules={[
                  {
                    required: true,
                    message: "Please select if the term is ss8",
                  },
                ]}
              >
                <Radio.Group defaultValue={0}>
                  <Radio.Button value={0}>No</Radio.Button>
                  <Radio.Button value={1}>Yes</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.select_school !== currentValues.select_school}
          >
            {({ getFieldValue }) =>
              getFieldValue('select_school') == 1 ? (
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="installment_1"
                      label="Installment 1 weeks"
                    >
                      <Input type='number' placeholder="Please enter installment 1 weeks" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="installment_2"
                      label="Installment 2 weeks"
                    >
                      <Input type='number' placeholder="Please enter installment 2 weeks" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="installment_3"
                      label="Installment 3 weeks"
                    >
                      <Input type='number' placeholder="Please enter installment 3 weeks" />
                    </Form.Item>
                  </Col>
                </Row>
              ) : null
            }
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
