import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MakeRequest from "../../Utils/MakeRequest";

export const fetchTestRegistrations = createAsyncThunk(
  "testRegsitration/fetch",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).get(
      "/fetchTestRegistrations"
    );
    return response.data;
  }
);

export const fetchConfig = createAsyncThunk(
  "testRegistration/config",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).get(
      "/fetchConfigurations"
    );
    return response.data
  }
);

export const updateconfig = createAsyncThunk(
  "testRegistration/update",
  async(data,thunkAPI)=>{
    const response = await MakeRequest(null,thunkAPI).post(
      "/editTestRegistrationConfig",
      data.body
    );
    return response.data
  }
)

export const deleteTestRegistration = createAsyncThunk(
  "testRegistration/delete",
  async(data,thunkAPI)=>{
    const response = await MakeRequest(null,thunkAPI).post(
      "/deleteTestRegistration",
      data.body
    );
    return response.data
  }
)

export const archiveOldRecords = createAsyncThunk(
  "testRegistration/archive",
  async(data,thunkAPI)=>{
    const response = await MakeRequest(null,thunkAPI).post(
      "archiveOldTestRegistraions"
    )
    return response.data
  }
)
const testRegistrationSlice = createSlice({
  name: "testRegistration",
  initialState: {
    data: [],
    loading: false,
    config:undefined
  },
  extraReducers: {
    [fetchTestRegistrations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.details;
    },
    [fetchTestRegistrations.pending]: (state) => {
      state.loading = true;
    },
    [fetchTestRegistrations.rejected]: (state) => {
      state.loading = false;
    },
    [fetchConfig.pending]:(state)=>{
      state.loading = true;
    },
    [fetchConfig.rejected]:(state)=>{
      state.loading = false;
    },
    [fetchConfig.fulfilled]:(state,{payload})=>{
      payload.details.TEST_REGISTRATION_GRADES = JSON.parse(payload.details.TEST_REGISTRATION_GRADES)
      state.config = payload.details
      state.loading = false;
    },
    [deleteTestRegistration.fulfilled]: (state, { payload }) => {
      let newData = state.data.filter((x) => x.id !== payload.details);
      state.data = newData;
    },
  },
});

export default testRegistrationSlice.reducer;
