import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import AddCrm from "../Components/AddCrm";
import CrmComments from "../Components/CrmComments";
import DeleteCrm from "../Components/DeleteCrm";
import EditCrm from "../Components/EditCrm";
import { fetchCrms } from "../Redux/Slices/CrmSlice";

export default function Crms() {
  const dispatch = useDispatch();
  const [searchedText, setSearchedText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { token } = useSelector((state) => state.auth);
  const { data, loading } = useSelector((state) => state.crms);
  useEffect(() => {
    dispatch(
      fetchCrms({
        token: token,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getColor = (status) => {
    switch (status) {
      case "New":
        return "magenta";
      case "Spoke & awaiting":
        return "default";
      case "Trial":
        return "gold";
      case "Confirmed":
        return "green";
      case "Declined":
        return "red";
      case "Send info":
        return "blue";
      case "See comments":
        return "cyan";
      case "Low":
        return "red";
      case "Medium":
        return "gold";
      case "High":
        return "green";
      default:
        break;
    }
  };
  const searchInput = useRef();
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchedText("");
  };
  const getGradeSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString() === value : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) =>
        a.name.split(" ")[0].localeCompare(b.name.split(" ")[0]),
    },
    {
      title: "Outcome",
      dataIndex: "outcome",
      key: "outcome",
      filters: [
        {
          text: "New",
          value: "New",
        },
        {
          text: "Spoke & awaiting",
          value: "Spoke & awaiting",
        },
        {
          text: "Trial",
          value: "Trial",
        },
        {
          text: "Confirmed",
          value: "Confirmed",
        },
        {
          text: "Declined",
          value: "Declined",
        },
        {
          text: "Send info",
          value: "Send info",
        },
        {
          text: "See comments",
          value: "See comments",
        },
        {
          text:"Info Sent",
          value:"Info Sent"
        }
      ],
      onFilter: (value, record) => {
        return record.outcome === value;
      },
      filterMultiple: false,
      render: (status, row) => <Tag color={getColor(status)}>{status}</Tag>,
    },
    {
      title: "Probablity",
      dataIndex: "probablity",
      key: "probablity",
      filters: [
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "High",
          value: "High",
        },
      ],
      onFilter: (value, record) => {
        return record.probablity === value;
      },
      filterMultiple: false,
      render: (status, row) => <Tag color={getColor(status)}>{status}</Tag>,
    },
    // {
    //   title: "Suburb",
    //   dataIndex: "suburb",
    //   key: "suburb",
    //   ...getColumnSearchProps("suburb"),
    //   sorter: (a, b) =>
    //     a.suburb.localeCompare(b.suburb),
    // },
    {
      title: "Phone Number",
      dataIndex: "contact_number",
      key: "contact_number",
      responsive: ["md"],
      ...getColumnSearchProps("contact_number"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      ...getGradeSearchProps("grade"),
    },
    {
      title: "Subjects",
      dataIndex: "subjects",
      key: "subjects",
      ...getColumnSearchProps,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      filters: [
        {
          text: "Email",
          value: "Email",
        },
        {
          text: "Facebook",
          value: "Facebook",
        },
        {
          text: "Google",
          value: "Google",
        },
        {
          text: "Direct Contact",
          value: "Direct Contact",
        },
        {
          text: "Info Session Sept 2021",
          value: "Info Session Sept 2021",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.source === value;
      },
    },
    // {
    //   title: "Date",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (text, record) => moment(text).format("Do-MMM-YY @ hh:mm a"),
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <EditCrm record={record} />
          <DeleteCrm record={record} />
          <CrmComments record={record} />
        </Space>
      ),
    },
  ];
  return (
    <div style={{ paddingTop: 16 }}>
      <Table
        columns={columns}
        loading={loading}
        dataSource={[...data].map((x, i) => ({ ...x, key: i }))}
        pagination={{ position: ["none", "bottomCenter"] }}
        size="middle"
        scroll={{ scrollToFirstRowOnChange: true }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <p style={{ margin: 4 }}>{record.message}</p>
              <i style={{ margin: 4 }}>Created By {record.created_by}</i>
            </>
          ),
          rowExpandable: (record) => record.message !== null,
        }}
        //   rowSelection={{
        //     type: "checkbox",
        //     selectedRowKeys: homeData.ids,
        //     onChange: (selectedRowKeys) => {
        //       dispatch(setIds(selectedRowKeys));
        //     },
        //   }}
      />
      <AddCrm />
    </div>
  );
}
