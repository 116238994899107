import { Card, Divider, Spin, Row, Col, Statistic } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarChart from "../Components/Dashboard/BarChart";
import HorizontalBarChart from "../Components/Dashboard/HorizontalBarChart";
import { LineChart } from "../Components/Dashboard/LineChart";
import PieChart from "../Components/Dashboard/PieChart";
import { getDashboard } from "../Redux/Slices/DashboardSlice";

export default function Dashboard() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const dash = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(getDashboard({ token: auth.token }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dash.data == null ? (
    <div
      style={{
        marginTop: 16,
        padding: 16,
        width: "100%",
        height: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Spin />
    </div>
  ) : dash.data.hasOwnProperty("box_1") ? (
    <div>
      <Divider>Tutors</Divider>
      <Row gutter={16} style={{ marginTop: 16 }} justify="center">
        {Object.entries(dash.data.box_5).map(([title, value]) => (
          <Col key={title}>
            <Card>
              <Statistic
                title={title.toUpperCase().replace("_", " ")}
                value={value}
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Divider>CRMS</Divider>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Card title="CRM entries" size="small">
            <LineChart
              data={dash.data.box_3}
              color="rgba(255, 124, 67, 1)"
              title={"Monthly CRM Entries"}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card title="CRM Outcomes" size="small">
            <BarChart
              data={dash.data.box_4}
              color="#d45087"
              title={"Outcome"}
            />
          </Card>
        </Col>
      </Row>
      <Divider>Students</Divider>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Card title="Number of Students by Term" size="small">
            <HorizontalBarChart
              data={dash.data.box_1}
              color="#003f5c"
              title={"No. of students by term"}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card
            title="Revenue by Term"
            size="small"
            style={{ display: "flex", height: "100%", flexDirection: "column" }}
            bodyStyle={{ display: "flex", flex: 1 }}
          >
            <div style={{ flex: 1 }}>
              <PieChart
                data={dash.data.box_2}
                title={"No. of students by term"}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <div
      style={{
        marginTop: 16,
        padding: 16,
        width: "100%",
        height: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <h4>Welcome Back!</h4>
    </div>
  );
}
