import { HistoryOutlined } from "@ant-design/icons";
import {
  Spin,
  Tooltip,
  Button,
  Drawer,
  notification,
  Row,
  Form,
  Input,
  Col,
  List,
  Badge,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCrmComment } from "../Redux/Slices/CrmSlice";
const { TextArea } = Input;
export default function CrmComments({ record }) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const addData = async () => {
    if (comment.trim() === "") {
      const key = `open${Date.now()}`;
      notification.error({
        message: "Error Occured",
        description: "Please type the comment",
        key,
      });
      return;
    }
    setLoading(true);
    let data = {
      token: auth.token,
      body: {
        id: record.id,
        comment: comment,
      },
    };
    let response = await dispatch(addCrmComment(data));
    if (response.payload) {
      setComment("");
    }
    setLoading(false);
  };
  // const archiveData = async (commentId)=>{
  //     console.log(commentId);
  //     if(window.confirm("Are you sure you want to archive this comment?")){
  //         setLoading(true)
  //         let data = {
  //             token:auth.token,
  //             body:{
  //                 id:commentId
  //             }
  //         }
  //         await dispatch(archiveComment(data))
  //         setLoading(false)
  //     }
  // }
  return (
    <>
      <Tooltip title="Comments">
        <Badge dot={record.comments}>
          <Button
            shape="circle"
            icon={<HistoryOutlined />}
            onClick={() => setVisible(true)}
          />
        </Badge>
      </Tooltip>
      <Drawer
        destroyOnClose={true}
        title="Comments"
        width={window.innerWidth > 900 ? 720 : "100%"}
        onClose={() => setVisible(false)}
        visible={visible}
        footer={
          <Row gutter={4}>
            <Col flex="auto">
              <Form.Item>
                <TextArea
                  rows={1}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button loading={loading} onClick={addData} type="primary">
                  Add Comment
                </Button>
              </Form.Item>
            </Col>
          </Row>
        }
      >
        <>
          <List
            itemLayout="horizontal"
            dataSource={record.comments ? JSON.parse(record.comments) : []}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.comment}
                  description={`updated by ${item.user} on ${moment(
                    item.timestamp
                  ).format("ddd , Do MMM YY, h:mm a")}`}
                />
              </List.Item>
            )}
          />

          {loading ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : null}
        </>
      </Drawer>
    </>
  );
}
