import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Input, Row, Tooltip, Form, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCrm } from "../Redux/Slices/CrmSlice";
const { TextArea } = Input;
const { Option } = Select;
export default function EditCrm({ record }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    form.setFieldsValue(record);
    // eslint-disable-next-line
  }, [record]);
  const sendData = async () => {
    let isValid = await form.validateFields();
    if (isValid) {
      setloading(true);
      let data = form.getFieldsValue();
      data.id = record.id;
      const response = await dispatch(
        editCrm({ token: auth.token, body: data })
      );
      if (response.payload) {
        setloading(false);
        setVisible(false);
      } else {
        setloading(false);
      }
    }
  };
  return (
    <>
      <Tooltip title="Edit">
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => setVisible(true)}
        />
      </Tooltip>
      <Drawer
        destroyOnClose={true}
        title="Edit CRM"
        width={window.innerWidth > 900 ? 720 : "100%"}
        onClose={() => setVisible(false)}
        visible={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => setVisible(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                sendData();
              }}
              type="primary"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            ignore_end_date: false,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter student name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="contact_number" label="Contact Number">
                <Input placeholder="Please enter contact number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="email" label="Email">
                <Input placeholder="Please enter email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="suburb" label="Suburb">
                <Input placeholder="Please enter suburb" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="grade" label="Grade">
                <Input placeholder="Please enter grade" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="subjects" label="Subjects">
                <Input placeholder="Please enter subjects" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="outcome" label="Outcome">
                <Select placeholder="Please choose the outcome">
                  <Option value={null}>Select</Option>
                  <Option value="New">New</Option>
                  <Option value="Spoke & awaiting">Spoke & awaiting</Option>
                  <Option value="Trial">Trial</Option>
                  <Option value="Confirmed">Confirmed</Option>
                  <Option value="Declined">Declined</Option>
                  <Option value="Send info">Send info</Option>
                  <Option value="See comments">See comments</Option>
                  <Option value="Info Sent">Info Sent</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="probablity" label="Probablity">
                <Select placeholder="Please choose the probablity">
                  <Option value={null}>Select</Option>
                  <Option value="Low">Low</Option>
                  <Option value="Medium">Medium</Option>
                  <Option value="High">High</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="source" label="Source">
                <Select placeholder="Please choose the source">
                  <Option value="Email">Email</Option>
                  <Option value="Facebook">Facebook</Option>
                  <Option value="Google">Google</Option>
                  <Option value="Direct Contact">Direct Contact</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="message" label="Message">
                <TextArea rows={4} placeholder="Please enter message" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
