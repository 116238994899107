import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  DownloadOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Table,
  Tooltip,
  Button,
  Drawer,
  Divider,
  Input,
  Space,
  Tag,
  Row,
  Col,
  Statistic,
  Card,
  Select,
} from "antd";
import ViewInvoice from "../Components/ViewInvoice";
import AddStudent from "../Components/AddStudent";
import EditStudent from "../Components/EditStudent";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadTerm,
  getHomeData,
  setIds,
  setSelectedTerm,
  setSelectedYear,
} from "../Redux/Slices/HomeSlice";
import SendInvoices from "../Components/SendInvoices";
import Email from "../Components/Email";
import DeleteStudent from "../Components/DeleteStudent";
import Comments from "../Components/Comments";
import CopyStudent from "../Components/CopyStudent";
const { Option } = Select;
export default function Home() {
  const searchInput = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const homeData = useSelector((state) => state.home);
  useEffect(() => {
    fetchHomeData();
    // eslint-disable-next-line
  }, []);
  const [searchedText, setSearchedText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [grades, setGrades] = useState();
  useEffect(() => {
    if (homeData.data.length) {
      let gradeWise = {}
      homeData.data.forEach(element => {
        if (gradeWise[element.grade]) {
          gradeWise[element.grade] += 1
        } else {
          gradeWise[element.grade] = 1
        }
      });
      setGrades(gradeWise)
    }
  }, [homeData])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm()
    setSearchedText('');
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) =>
        a.name.split(" ")[0].localeCompare(b.name.split(" ")[0]),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      responsive: ["lg"],
      ...getColumnSearchProps("phone_number"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      responsive: ["lg"],
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      filters: [...new Set(homeData?.data?.map((x) => x.grade))].map((x) => ({
        text: x,
        value: x,
      })),
      onFilter: (value, record) => record.grade === value,
    },
    {
      title: "Confirmed",
      dataIndex: "confirmed",
      key: "confirmed",
      filters: [
        {
          text: "Confirmed",
          value: 1,
        },
        {
          text: "Not Confirmed",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.confirmed === value,
      sorter: (a, b) => a.confirmed - b.confirmed,
      // sortDirections: ['ascend'],
      render: (status) => (
        <Tag color={status == 0 ? "volcano" : "green"}>
          {status == 0 ? "No" : "Yes"}
        </Tag>
      ),
    },
    {
      title: "Invoice Sent",
      dataIndex: "invoice_sent",
      key: "invoice_sent",
      filters: [
        {
          text: "Sent",
          value: 1,
        },
        {
          text: "Not Sent",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.invoice_sent === value,
      sorter: (a, b) => a.invoice_sent - b.invoice_sent,
      // sortDirections: ['ascend'],
      render: (status) => (
        <Tag color={status == 0 ? "volcano" : "green"}>
          {status == 0 ? "Not Sent" : "Sent"}
        </Tag>
      ),
    },
    {
      title: "Payment Received",
      dataIndex: "payment_received",
      key: "payment_received",
      filters: [
        {
          text: "Received",
          value: 1,
        },
        {
          text: "Partially Received",
          value: 2,
        },
        {
          text: "Not Received",
          value: 0,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        if (value == 2) {
          return record.payment_received > 1;
        } else {
          return record.payment_received === value;
        }
      },
      sorter: (a, b) => a.payment_received - b.payment_received,
      // sortDirections: ['ascend'],
      render: (status, row) => (
        <Tag color={status == 0 ? "volcano" : status == 1 ? "green" : "gold"}>
          {status == 0
            ? "Not Received"
            : status == 1
              ? "Received"
              : `Received $${status} / $${row.amount}`}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Comments id={record.id} />
          <CopyStudent student={record} />
          <Tooltip title="View Invoice">
            <Button
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => {
                setSelected(record);
                setVisible(true);
              }}
            />
          </Tooltip>
          <SendInvoices single ids={[record.id]} />
          <EditStudent record={record} />
          <DeleteStudent record={record} />
        </Space>
      ),
    },
  ];
  const fetchHomeData = async () => {
    let response = await dispatch(
      getHomeData({
        token: auth.token,
      })
    );
    if (response.payload) {
      let current = response.payload.details.terms.find((x) => x.current == 1);
      dispatch(setSelectedTerm(current.id));
      dispatch(setSelectedYear(current.year));
    }
  };
  const setYear = (year) => {
    dispatch(setSelectedYear(year));
    dispatch(setSelectedTerm(null));
  };
  const fetchTermData = (id) => {
    let data = {
      term_id: id,
      token: auth.token,
    };
    dispatch(getHomeData(data));
    dispatch(setSelectedTerm(id));
  };
  const download = async () => {
    let data = {
      term_id: homeData.selected_term,
      token: auth.token,
    };
    let response = await dispatch(downloadTerm(data));
    if (response.payload) {
      window.open(response.payload.details, "_blank").focus();
    }
  };
  return (
    <div>
      {homeData.total_amount ? (
        <Row gutter={16} style={{ marginTop: 16 }} justify="center">
          <Col>
            <Card>
              <Statistic
                title={"Total Amount"}
                value={homeData.total_amount}
                loading={homeData.loading}
                prefix={"$"}
              />
            </Card>
          </Col>
          <Col>
            <Card>
              <Statistic
                title={"Pending Amount"}
                value={homeData.pending_payments}
                loading={homeData.loading}
                prefix={"$"}
              />
            </Card>
          </Col>

          <Col>
            <Card>
              <Statistic
                title={"Cash Payments"}
                value={homeData.cash_payments}
                loading={homeData.loading}
                prefix={"$"}
              />
            </Card>
          </Col>
          <Col>
            <Card>
              <Statistic
                title={"Bank Payments"}
                value={homeData.bank_payments}
                loading={homeData.loading}
                prefix={"$"}
              />
            </Card>
          </Col>
          <Col>
            <Card>
              <Statistic
                title={"Total Students"}
                value={homeData.data.length}
                loading={homeData.loading}
              />
            </Card>
          </Col>
        </Row>
      ) : null}
      <Divider />
      <Row align="stretch" justify="space-between">
      {grades ?
            Object.keys(grades).map(grade => (
              <Col>
                <Card>
                  <Statistic
                    title={`Grade ${grade}`}
                    value={grades[grade]}
                  />
                </Card>
              </Col>
            ))
            :
            null}
      </Row>
      <Divider/>
      <Row align="stretch" justify="space-between">
        <Space>
          <Email
            title="Send Email"
            disabled={homeData.ids.length === 0}
            ids={homeData.ids}
          />
          <SendInvoices
            title="Send Invoice"
            disabled={homeData.ids.length === 0}
            ids={homeData.ids}
          />
        </Space>
        <Space>
          <Select
            value={homeData.selected_year}
            style={{ width: 220 }}
            onChange={setYear}
          >
            {homeData.years?.map((x) => (
              <Option value={x} key={`year${x}`}>
                {x}
              </Option>
            ))}
          </Select>
          <Select
            value={homeData.selected_term}
            style={{ width: 220 }}
            onChange={fetchTermData}
          >
            {homeData.terms
              ? homeData.terms
                .filter((x) => x.year == homeData.selected_year)
                .map((x) => (
                  <Option value={x.id} key={`term${x.id}`}>
                    {x.term}
                  </Option>
                ))
              : null}
          </Select>
          <Button
            onClick={download}
            icon={<DownloadOutlined />}
            shape="circle"
          />
        </Space>
        <Space>
          {/* <SendInvoices title="Send All" /> */}
          <AddStudent />
        </Space>
      </Row>
      <Divider />
      <Table
        columns={columns}
        loading={homeData.loading}
        dataSource={homeData.data}
        pagination={{ position: ["none", "bottomCenter"] }}
        size="middle"
        scroll={{ scrollToFirstRowOnChange: true }}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: homeData.ids,
          onChange: (selectedRowKeys) => {
            dispatch(setIds(selectedRowKeys));
          },
        }}
      />
      <Drawer
        title="Invoice"
        width={window.innerWidth > 900 ? 720 : "100%"}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <ViewInvoice student={selected} />
      </Drawer>
    </div>
  );
}
