import { useEffect, useState } from "react"
import MakeRequest from "../../Utils/MakeRequest"
import { useDispatch } from "react-redux"
import { Button, Col, Form, Input, Radio, Row } from "antd"

export function FinalStep({ term_id, taskId }) {
    const [term, setTerm] = useState(null)
    const [done, setDone] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    useEffect(() => {
        fetchTerm()
    }, [])
    const fetchTerm = async () => {
        const form_data = new FormData()
        form_data.append('id', term_id)
        const response = await MakeRequest(null, dispatch).post('/fetchTerm', form_data)
        if (response) {
            setTerm(response.data.details)
        }
    }
    const sendData = async () => {
        let data = form.getFieldsValue();
        var form_data = new FormData();
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        form_data.append('task_id', taskId)
        form_data.append('term_id', term_id)
        const response = await MakeRequest(null, dispatch).post("/finishUpload", form_data)
        if (response) {
            setDone(true)
        }
    }
    return (
        done ?
            <div>
                <p>All done! you can close this tab now.</p>
            </div> :
            term ?
                <div>
                    <Form layout="vertical" form={form}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="action"
                                    label="Do you want to add or replace to the existing data if any?"
                                    rules={[{ required: true, message: 'Please select if the action' }]}
                                >
                                    <Radio.Group defaultValue={'add'}>
                                        <Radio.Button value={'replace'}>Replace</Radio.Button>
                                        <Radio.Button value={'add'}>Add</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {term.select_school == 1 ?

                            <>
                                <p>Do you want to override the default installment weeks ?</p>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="installment_1"
                                            label="Installment 1 weeks"
                                        >
                                            <Input type='number' placeholder="Please enter installment 1 weeks" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="installment_2"
                                            label="Installment 2 weeks"
                                        >
                                            <Input type='number' placeholder="Please enter installment 2 weeks" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="installment_3"
                                            label="Installment 3 weeks"
                                        >
                                            <Input type='number' placeholder="Please enter installment 3 weeks" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </> :
                            null}
                    </Form>
                    <Button onClick={sendData}>Finish Upload</Button>
                </div>
                :
                null
    )
}