import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Radio,
  notification,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateStudent } from "../Redux/Slices/HomeSlice";
const { Option } = Select;
export default function AddStudent({ update }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const auth = useSelector((state) => state.auth);
  const homeData = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const sendData = async () => {
    let isValid = await form.validateFields();
    if (isValid) {
      let data = form.getFieldsValue();
      data.payment_due_date = data.payment_due_date.format("DD-MM-yyyy");
      if (data.payment_received === 2) {
        if (data.partial_amount) {
          data.payment_received = data.partial_amount;
        } else {
          notification.error({
            message: "Error Occured",
            description: "Please enter partially received amount",
            key: `open${Date.now()}`,
          });
          return;
        }
      }
      for (let [key, value] of Object.entries(data)) {
        if (value === undefined) {
          delete data[key];
        }
      }
      data.term_id = homeData.selected_term;
      const response = await dispatch(
        addUpdateStudent({ token: auth.token, body: data })
      );
      if (response.payload) {
        setVisible(false);
        form.resetFields();
      }
      // let response = await fetch(`${url}/addUpdateStudent`, {
      //     method: "POST", body: JSON.stringify(data), headers: {
      //         'Accept': 'application/json',
      //         'Content-Type': 'application/json',
      //         'Authorization': auth.token
      //     }
      // })
      // let responseJson = await response.json()
      // if (responseJson.success === true) {
      //     dispatch(getHomeData({ token: auth.token }))
      //     setVisible(false)
      //     form.resetFields()
      // }
    }
  };
  useEffect(() => {
    form.setFieldsValue({ invoice_sent: 0 });
    form.setFieldsValue({ payment_received: 0 });
    form.setFieldsValue({ confirmed: 0 });
  }, [form]);
  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        <PlusOutlined /> Add Student
      </Button>

      <Drawer
        destroyOnClose={true}
        title="Add Student"
        width={window.innerWidth > 900 ? 720 : "100%"}
        onClose={() => setVisible(false)}
        visible={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => setVisible(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                sendData();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            ignore_end_date: false,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter student name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phone_number" label="Phone Number">
                <Input placeholder="Please enter phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="email" label="Email">
                <Input placeholder="Please enter email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="email_secondary" label="Secondary Email">
                <Input placeholder="Please enter secondary email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="zoom" label="Zoom ID">
                <Input placeholder="Please enter zoom ID" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="grade"
                label="Grade"
                rules={[{ required: true, message: "Please enter grade" }]}
              >
                <Input placeholder="Please enter grade" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[{ required: true, message: "Please enter subject" }]}
              >
                <Input placeholder="Please enter subject" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="day"
                label="Day"
                rules={[{ required: true, message: "Please choose the day" }]}
              >
                <Select placeholder="Please choose the day">
                  <Option value="Sunday">Sunday</Option>
                  <Option value="Monday">Monday</Option>
                  <Option value="Tuesday">Tuesday</Option>
                  <Option value="Wednesday">Wednesday</Option>
                  <Option value="Thursday">Thursday</Option>
                  <Option value="Friday">Friday</Option>
                  <Option value="Saturday">Saturday</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="time"
                label="Time"
                rules={[{ required: true, message: "Please enter time" }]}
              >
                <Input placeholder="Please enter time" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="session_fee"
                label="Fee/Session"
                rules={[
                  { required: true, message: "Please enter fee/session" },
                ]}
              >
                <Input placeholder="Please enter fee/session" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="session_discount"
                label="Discount/Session"
                rules={[
                  { required: true, message: "Please enter discount/session" },
                ]}
              >
                <Input
                  placeholder="Please enter discount/session"
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="payment_due_date"
                label="Payment Due Date"
                rules={[
                  { required: true, message: "Please choose the payment due date" },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter number of sessions",
                  },
                ]}
                name="number_of_sessions"
                label="Number of sessions"
              >
                <Input
                  placeholder="Please enter number of sessions"
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="invoice_sent"
                label="Invoice Sent ?"
                rules={[
                  {
                    required: true,
                    message: "Please select if the invoice is sent",
                  },
                ]}
              >
                <Radio.Group defaultValue={0}>
                  <Radio.Button value={0}>No</Radio.Button>
                  <Radio.Button value={1}>Yes</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="payment_received"
                label="Payment received ?"
                rules={[
                  {
                    required: true,
                    message: "Please select if the payment is received",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio.Button value={0}>No</Radio.Button>
                  <Radio.Button value={1}>Yes</Radio.Button>
                  <Radio.Button value={2}>Partial</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="confirmed"
                label="Confirmed ?"
                rules={[
                  { required: true, message: "Please select if the confirmed" },
                ]}
              >
                <Radio.Group defaultValue={0}>
                  <Radio.Button value={0}>No</Radio.Button>
                  <Radio.Button value={1}>Yes</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="partial_amount"
                label="Partially received amount"
              >
                <Input
                  placeholder="Enter Partially received amount"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="payment_method" label="Payment Method">
                <Radio.Group defaultValue={null}>
                  <Radio.Button value={null}>None</Radio.Button>
                  <Radio.Button value={"Cash"}>Cash</Radio.Button>
                  <Radio.Button value={"Bank"}>Bank</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
