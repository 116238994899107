import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const generateData = (data, color, title) => {
  let labels = Object.keys(data);
  const fdata = {
    labels,
    datasets: [
      {
        label: title,
        data: labels.map((item) => data[item]),
        backgroundColor: color,
      },
    ],
  };
  return fdata;
};
export default function HorizontalBarChart({ data, color, options, title }) {
  return (
    <Bar
      options={{
        indexAxis: "y",
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        title: {
          display: true,
          text: title,
        },
        ...options,
      }}
      data={generateData(data, color, title)}
    />
  );
}
