import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const colorList = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];
const generateData = (data, title) => {
  let labels = Object.keys(data);
  const fdata = {
    labels,
    datasets: [
      {
        label: title,
        data: labels.map((item) => data[item]),
        backgroundColor: labels.map((item, index) => {
          let charInt = 0;
          item.split("").forEach((x) => {
            charInt += x.charCodeAt(0);
          });
          return colorList[charInt % 7];
        }),
      },
    ],
  };
  return fdata;
};
export default function PieChart({ data, title }) {
  return (
    <Pie
      options={{
        responsive: true,
        maintainAspectRatio:false,
        plugins: {
          legend: {
            position:"top",
          },
        },
      }}
      data={generateData(data, title)}
    />
  );
}
