import {
  Divider,
  Row,
  Select,
  Form,
  Input,
  Table,
  Checkbox,
  Col,
  Button,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTerms, setTerms } from "../../Redux/Slices/DashboardSlice";
import { url } from "../../Utils/Config";
import AddTerm from "./Terms/AddTerm";
import { CheckOutlined, DeleteOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import EditTerm from "./Terms/EditTerm";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
const { Option } = Select;
export default function Terms() {
  const dashboardData = useSelector((state) => state.dashboard);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const columns = [
    {
      title: "Term",
      dataIndex: "term",
      key: "name",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Select School",
      dataIndex: "select_school",
      key: "select_school",
      render: (text) => (
        text == 1 ? <CheckOutlined /> : null
      )
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Default",
      dataIndex: "current",
      key: "default",
      render: (text, row) => (
        <Checkbox
          onChange={(check) => {
            if (check) {
              updateDefault(row.id);
            }
          }}
          checked={text == 1 ? true : false}
        ></Checkbox>
      ),
    },
    {
      title: "Actions",
      key: 'action',
      render: (_, record) => (
        <Space>
          <Tooltip title={record.archive == 1 ? "Put Back" : "Archive"}>
            <Button shape="circle" danger={record.archive == 1 ? false : true} icon={record.archive == 1 ? <EyeOutlined /> : <DeleteOutlined />} onClick={() => { setTermStatus(record.id) }} />
          </Tooltip>
          <EditTerm record={record} />
          <Button shape="circle" icon={<UploadOutlined/>} onClick={()=>window.open(`/upload/${record.id}`,'_blank')}/>
        </Space>
      )
    }
  ];
  useEffect(() => {
    if (!dashboardData.terms) {
      dispatch(getTerms());
    } else {
      setSelectedYear(dashboardData.terms.find((x) => x.current === 1).year);
    }
  }, [dashboardData.terms]);
  const updateDefault = async (id) => {
    const data = new FormData();
    data.append("id", id);
    const response = await fetch(`${url}/updateDefaultTerm`, {
      method: "POST",
      body: data,
      credentials: "include",
    });
    if (response) {
      dispatch(getTerms());
    }
  };
  const setTermStatus = async (term_id) => {
    const data = new FormData();
    data.append('id', term_id);
    const response = await fetch(`${url}/setTermStatus`, {
      method: "POST",
      body: data,
      credentials: 'include',
    })
    if (response) {
      const data = await response.json()
      dispatch(setTerms(data.details))
    }
  }
  return (
    <div>
      <h4>Terms</h4>
      <Row justify={"space-between"}>
        <Col>
          <Select
            style={{ width: 200 }}
            value={selectedYear}
            placeholder="Select Year"
            onChange={(value) => {
              setSelectedYear(value);
            }}
          >
            {dashboardData.termYears?.map((x) => (
              <Option value={x} key={`${x}`}>
                {x}
              </Option>
            ))}
          </Select>
        </Col>

        <Col>
          <AddTerm />
        </Col>
      </Row>
      <Space size={2}>
        <div></div>
      </Space>
      {dashboardData.terms ? (
        <Table columns={columns} dataSource={dashboardData.terms.filter((x) => x.year === selectedYear)} pagination={false} />
      ) : null}
    </div>
  );
}
