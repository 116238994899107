import { Progress } from "antd";
import { useState } from "react"
import MakeRequest from "../../Utils/MakeRequest";
import { useDispatch } from "react-redux";

export default function FileUpload({ setTaskId, setStep }) {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('normal')
    const [statusComment, setStatusComment] = useState('Idle')
    const dispatch = useDispatch()
    async function onFileSelect(event) {
        const form_data = new FormData()
        form_data.append('file', event.target.files[0])
        setProgress(10)
        setStatusComment('Uploading file...')
        setStatus("active")
        const response = await MakeRequest('multipart/form-data', dispatch).post('/uploadTermFile', form_data);
        setProgress(35)
        if (response) {
            setProgress(50)
            checkHeaders(response.data.details)
            setTaskId(response.data.details)
        } else {
            setStatusComment('Upload Failed')
            setStatus('exception')
        }
    }
    async function checkHeaders(id) {
        const form_data = new FormData()
        form_data.append('id', id)
        setStatusComment("Checking Headers....")
        setProgress(60)
        const response = await MakeRequest(null, dispatch).post('/checkFileHeaders', form_data)
        if (response) {
            setProgress(75)
            standardizeData(id)
        } else {
            setStatusComment('Header check failed please check the file and upload again.....')
            setStatus('exception')
        }
    }
    async function standardizeData(id) {
        const form_data = new FormData()
        form_data.append('id', id)
        setStatusComment("Transposing data....")
        setProgress(85)
        const response = await MakeRequest(null, dispatch).post('/standardizeData', form_data)
        if (response) {
            setProgress(100)
            setStep(prev => prev + 1)
        } else {
            setStatusComment('Data transposing failed.')
            setStatus('exception')
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input type="file" accept=".csv" onChange={(event) => onFileSelect(event)} />
            <br></br>
            <a href={"/sample.csv"} download="sample">Download Sample file</a>
            <br></br>
            <Progress percent={progress} status={status} />
            <div>{statusComment}</div>
        </div>
    )
}