/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Spin, Form, Switch, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { archiveOldRecords, fetchConfig, updateconfig } from "../../Redux/Slices/TestRegistrationSlice";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};

function TestRegistrationSettings() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const config = useSelector((state) => state.testRegistration);
  const [form] = Form.useForm();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    if (!config.config) {
      await dispatch(fetchConfig({ token: auth.token }));
    }
  };
  useEffect(() => {
    if (config.config) {
      form.setFieldsValue(config.config);
    }
  }, [config.config]);
  const [loading, setloading] = useState(false)
  const sendData = async () => {
    let isValid = await form.validateFields();
    if (isValid) {
      setloading(true);
      let data = form.getFieldsValue();
      console.log(data);
      await dispatch(
        updateconfig({ token: auth.token, body: data })
      );
      setloading(false)
    }
  }
  const archive = async () => {
    dispatch(archiveOldRecords({ token: auth.token }))
  }
  return !config.loading ? (
    <div>
      <Button type="danger" onClick={archive}>Archive Old Records</Button>
      <p><i>*Please archive old data before starting the payment link for new term</i></p>
      <Form form={form} layout="vertical">
        <Form.Item
          name="TEST_REGISTRATION_TERM_NAME"
          label="Term Name"
          rules={[{ required: true, message: "Please enter term name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="TEST_REGISTRATION_TERM_YEAR"
          label="Term Year"
          rules={[{ required: true, message: "Please enter term year" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="TEST_REGISTRATION_OPTION_1"
          label="Option 1"
          rules={[{ required: true, message: "Please enter option" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="TEST_REGISTRATION_OPTION_2"
          label="Option 2"
          rules={[{ required: true, message: "Please enter option" }]}
        >
          <Input />
        </Form.Item>
        <Form.List
          name="TEST_REGISTRATION_GRADES"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('At least 1 Grade'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
             <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Grades' : ''}
                  required={true}
                  key={field.key}
                >
                  <Space>
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input grade or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="Grade"
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                  </Space>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  
                  icon={<PlusOutlined />}
                >
                  Add Grade
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          name="TEST_REGISTRATION_LINK_UP"
          label="Registration Open"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
      <Button type="primary" loading={loading} onClick={sendData}>
        Save
      </Button>
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin />
    </div>
  );
}
export default TestRegistrationSettings;
