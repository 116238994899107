import { Col, Row, Steps } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import FileUpload from "../Components/Upload/FileUpload";
import { Display } from "../Components/Upload/Display";
import { FinalStep } from "../Components/Upload/FinalStep";

export default function Upload() {
    const [step, setStep] = useState(0)
    const [taskId, setTaskId] = useState(null)
    const params = useParams()
    return (
        <div style={{ marginTop: 16 }}>
            <Row gutter={16}>
                <Col span={4}>
                    <Steps
                        direction="vertical"
                        current={step}
                        size="small"
                        items={[
                            {
                                title: 'Upload',
                                description: 'File upload and integrity check'
                            },
                            {
                                title: 'Display',
                                description: 'Processed data manual check'
                            },
                            {
                                title: 'Final steps',
                                description: 'Choose the action for the uploaded data'
                            }
                        ]}
                    />
                </Col>
                <Col span={20}>
                    {step === 0 &&
                        <FileUpload setTaskId={setTaskId} setStep={setStep} />}
                    {step === 1 &&
                        <Display taskId={taskId} setStep={setStep}/>}
                    {step === 2 &&
                    <FinalStep taskId={taskId} term_id={params.id}/>}
                </Col>
            </Row>
        </div>
    )
}