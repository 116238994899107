import { useEffect, useState } from "react"
import MakeRequest from "../../Utils/MakeRequest"
import { useDispatch } from "react-redux"
import { Button, Table } from "antd"

export function Display({ taskId, setStep }) {
    const [data, setData] = useState(null)
    const [headers, setHeaders] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
        fetchTaskData()
    }, [taskId])
    async function fetchTaskData() {
        const form_data = new FormData()
        form_data.append('id', taskId)
        const response = await MakeRequest(null, dispatch).post('/getTaskData', form_data)
        if (response) {
            let displayData = [];
            let taksData = JSON.parse(response.data.details)
            let headers = taksData[0]
            taksData.forEach(element => {
                let display = {}
                for (let i = 0; i < headers.length; i++) {
                    display[headers[i]] = element[i]
                }
                displayData.push(display)
            });
            displayData.shift()
            setHeaders(headers)
            setData(displayData)
        }
    }
    return (
        <div>
            {data ?
                <>
                <Button type="primary" onClick={()=>setStep(prev=>prev+1)}>Accept Changes</Button>
                <br></br>
                <Table
                    columns={headers.map(x=>({
                        title:x,
                        dataIndex:x,
                        key:x
                    }))}
                    dataSource={[...data].map((x, i) => ({ ...x, key: i }))}
                    scroll={{
                        x:true
                    }}
                    size="small"
                    pagination={{pageSize:5}}
                />
                </>
                :
                null}
        </div>
    )
}