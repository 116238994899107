import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MakeRequest from "../../Utils/MakeRequest";

export const getHomeData = createAsyncThunk(
  "home/getData",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/getAllData",
      data
    );
    return response.data;
  }
);
export const sendInvoices = createAsyncThunk(
  "home/sendInvoice",
  async (data, thunkAPI) => {
    await MakeRequest(null, thunkAPI).post("/sendInvoices", data.body);
    thunkAPI.dispatch(getHomeData());
    return;
  }
);
export const addUpdateStudent = createAsyncThunk(
  "home/addUpdateStudent",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      data.body.id ? "/editStudent" : "/addStudent",
      data.body
    );
    return response.data;
  }
);
export const deleteStudent = createAsyncThunk(
  "home/deleteStudent",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/deleteStudent",
      data.body
    );
    return response.data;
  }
);
export const copyStudent = createAsyncThunk(
  "home/copyStudent",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/copyStudent",
      data.body
    );
    return response.data;
  }
);
export const getComments = createAsyncThunk(
  "home/getComments",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).get(
      `/getComments/${data.id}`
    );
    return response.data;
  }
);
export const addComment = createAsyncThunk(
  "home/addComment",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/addComment",
      data.body
    );
    return response.data;
  }
);
export const archiveComment = createAsyncThunk(
  "home/archiveComment",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/archiveComment",
      data.body
    );
    return response.data;
  }
);
export const sendMessage = createAsyncThunk(
  "home/sendMessage",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/sendMessage",
      data.body
    );
    return response.data;
  }
);
export const downloadTerm = createAsyncThunk(
  "home/download",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/downloadTerm",
      data
    );
    return response.data;
  }
);
const homeSlice = createSlice({
  name: "home",
  initialState: {
    loading: false,
    data: [],
    ids: [],
    total_amount: 0,
    pending_payments: 0,
    cash_payments: 0,
    bank_payments: 0,
    terms: [],
    years: [],
    comments: [],
    selected_term: null,
    selected_year: null,
  },
  reducers: {
    setIds: (state, action) => {
      state.ids = action.payload;
    },
    clearIds: (state) => {
      state.ids = [];
    },
    setSelectedTerm: (state, action) => {
      state.selected_term = action.payload;
    },
    setSelectedYear: (state, action) => {
      state.selected_year = action.payload;
    },
  },
  extraReducers: {
    [getHomeData.fulfilled]: (state, action) => {
      state.loading = false;
      let data = [];
      action.payload.details.students.forEach((item) => {
        item.key = item.id;
        data.push(item);
      });
      state.data = data;
      state.total_amount = action.payload.details.total_amount;
      state.pending_payments = action.payload.details.pending_amount;
      state.cash_payments = action.payload.details.cash;
      state.bank_payments = action.payload.details.bank;
      state.terms = action.payload.details.terms;
      state.years = [
        ...new Set(action.payload.details.terms.map((x) => x.year)),
      ];
    },
    [getHomeData.pending]: (state, action) => {
      state.loading = true;
    },
    [getHomeData.rejected]: (state, action) => {
      state.loading = false;
    },
    [addUpdateStudent.pending]: (state, action) => {
      state.loading = true;
    },
    [addUpdateStudent.rejected]: (state, action) => {
      state.loading = false;
    },
    [addUpdateStudent.fulfilled]: (state, action) => {
      state.loading = false;
      let data = action.payload.details;
      if (data.operation === "Update") {
        let newData = [];
        state.data.forEach((x) => {
          if (x.id === data.data.id) {
            newData.push(data.data);
          } else {
            newData.push(x);
          }
        });
        state.data = newData;
      } else {
        state.data.push(action.payload.details.data);
      }
      state.total_amount = data.stats.total_amount;
      state.pending_payments = data.stats.pending_amount;
      state.cash_payments = data.stats.cash;
      state.bank_payments = data.stats.bank;
    },
    [sendInvoices.fulfilled]: (state, action) => {
      state.ids = [];
      state.loading = false;
    },
    [sendInvoices.pending]: (state) => {
      state.loading = true;
    },
    [sendInvoices.rejected]: (state) => {
      state.loading = false;
    },
    [deleteStudent.fulfilled]: (state, action) => {
      let extraData = action.payload.details;
      state.loading = false;
      state.data = state.data.filter((x) => x.id !== extraData.id);
      state.total_amount = extraData.stats.total_amount;
      state.pending_payments = extraData.stats.pending_amount;
      state.cash_payments = extraData.stats.cash;
      state.bank_payments = extraData.stats.bank;
    },
    [deleteStudent.pending]: (state) => {
      state.loading = true;
    },
    [deleteStudent.rejected]: (state) => {
      state.loading = false;
    },
    [getComments.fulfilled]: (state, action) => {
      state.comments = action.payload.details;
    },
    [getComments.rejected]: (state, action) => {
      state.comments = [];
    },
    [addComment.fulfilled]: (state, action) => {
      state.comments = [...state.comments, action.payload.details];
    },
    [archiveComment.fulfilled]: (state, action) => {
      state.comments = state.comments.filter(
        (x) => x.id !== action.payload.details
      );
    },
    [copyStudent.fulfilled]: (state, action) => {
      let data = action.payload.details;
      state.data.push(action.payload.details.data);
      state.total_amount = data.stats.total_amount;
      state.pending_payments = data.stats.pending_amount;
      state.cash_payments = data.stats.cash;
      state.bank_payments = data.stats.bank;
    },
  },
});
export const { setIds, clearIds, setSelectedTerm, setSelectedYear } =
  homeSlice.actions;
export default homeSlice.reducer;
