import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const generateData = (data, color, title) => {
  let labels = Object.keys(data);
  const fdata = {
    labels,
    datasets: [
      {
        label: title,
        data: labels.map((item) => data[item]),
        backgroundColor: color.replace(/[^,]+(?=\))/, '0.5'),
        borderColor:color,
        fill:true
      },
    ],
  };
  return fdata;
};

export function LineChart({data,color,title}) {
  return (
    <Line
      // options={{
      //   responsive: true,
      //   plugins: {
      //     legend: {
      //       position: "top",
      //     },
      //   },
      // }}
      data={generateData(data,color,title)}
    />
  );
}
