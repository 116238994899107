import { EditOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Row, Col, Input, Tooltip, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import MakeRequest from '../../../Utils/MakeRequest';
import { useDispatch } from 'react-redux';
import { setTerms } from '../../../Redux/Slices/DashboardSlice';
export default function EditTerm({ record }) {
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    useEffect(() => {
        form.setFieldsValue(record)
        // form.setFieldsValue({ "start_date": moment(record.start_date, "DD-MM-YYYY") })
        // form.setFieldsValue({ "end_date": moment(record.end_date, "DD-MM-YYYY") })
        // eslint-disable-next-line
    }, [record])
    const sendData = async () => {
        let data = form.getFieldsValue();
        var form_data = new FormData();
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        form_data.append('id', record.id)
        const response = await MakeRequest(null, dispatch).post("/editTerm", form_data)
        if (response.status == 200) {
            dispatch(setTerms(response.data.details))
            form.resetFields();
            setVisible(false);
        }
    }

    return (
        <>
            <Tooltip title="Edit">
                <Button shape="circle" icon={<EditOutlined />} onClick={() => setVisible(true)} />
            </Tooltip>
            <Drawer
                destroyOnClose={true}
                title="Add Student"
                width={window.innerWidth > 900 ? 720 : "100%"}
                onClose={() => setVisible(false)}
                visible={visible}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => setVisible(false)} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            sendData()
                        }} type="primary">
                            Submit
                        </Button>
                    </div>
                }>
                <Form layout="vertical" form={form} initialValues={{
                    ignore_end_date: false
                }}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="term"
                                label="Term"
                                rules={[{ required: true, message: 'Please enter term name' }]}
                            >
                                <Input placeholder="Please enter term name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="year"
                                label="Year"
                            >
                                <Input type='number' placeholder="Please enter year" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="start_date"
                                label="Start Date"
                                rules={[{ required: true, message: 'Please choose the start date' }]}
                            >
                                <Input placeholder="Please enter start date" type="date" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="end_date"
                                label="End Date"
                                rules={[{ required: true, message: 'Please choose the end date' }]}
                            >
                                <Input placeholder="Please enter end date" type="date" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="select_school"
                                label="Select School ?"
                                rules={[{ required: true, message: 'Please select if the term is select school' }]}
                            >
                                <Radio.Group defaultValue={0}>
                                    <Radio.Button value={0}>No</Radio.Button>
                                    <Radio.Button value={1}>Yes</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.select_school !== currentValues.select_school}
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('select_school') == 1 ? (
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="installment_1"
                                            label="Installment 1 weeks"
                                        >
                                            <Input type='number' placeholder="Please enter installment 1 weeks" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="installment_2"
                                            label="Installment 2 weeks"
                                        >
                                            <Input type='number' placeholder="Please enter installment 2 weeks" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="installment_3"
                                            label="Installment 3 weeks"
                                        >
                                            <Input type='number' placeholder="Please enter installment 3 weeks" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : null
                        }
                    </Form.Item>

                </Form>
            </Drawer>
        </>
    )
}
