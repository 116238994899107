import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Space, Statistic, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestRegistrations } from "../Redux/Slices/TestRegistrationSlice";
import DeleteTestRegistration from "../Components/DeleteTestRegistration";

export default function TestRegistrations() {
  const dispatch = useDispatch();
  const [searchedText, setSearchedText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { token } = useSelector((state) => state.auth);
  const { data, loading } = useSelector((state) => state.testRegistration);
  const [grades, setGrades] = useState()
  useEffect(() => {
    if (data.length) {
      let gradeWise = {}
      data.forEach(element => {
        if (gradeWise[element.student_grade]) {
          gradeWise[element.student_grade] += 1
        } else {
          gradeWise[element.student_grade] = 1
        }
      });
      setGrades(gradeWise)
    }
  }, [data])
  const searchInput = useRef();
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchedText("");
  };
  const getGradeSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString() === value : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    dispatch(
      fetchTestRegistrations({
        token: token,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "student_name",
      key: "student_name",
      ...getColumnSearchProps("student_name"),
      sorter: (a, b) =>
        a.name.split(" ")[0].localeCompare(b.name.split(" ")[0]),
    },
    {
      title: "Parent Name",
      dataIndex: "parent_name",
      key: "parent_name",
      ...getColumnSearchProps("parent_name"),
      sorter: (a, b) =>
        a.name.split(" ")[0].localeCompare(b.name.split(" ")[0]),
    },
    {
      title: "Phone Number",
      dataIndex: "contact_number",
      key: "contact_number",
      responsive: ["md"],
      ...getColumnSearchProps("contact_number"),
    },
    {
      title: "Parent Email",
      dataIndex: "parent_email",
      key: "parent_email",
      responsive: ["md"],
    },
    {
      title: "Grade",
      dataIndex: "student_grade",
      key: "student_grade",
      ...getGradeSearchProps("student_grade"),
    },
    {
      title: "Mode",
      dataIndex: "delivery",
      key: "delivery",
      filters: [
        {
          text: "Collect",
          value: "Collect",
        },
        {
          text: "Email PDF",
          value: "Email PDF",
        },
      ],
      onFilter: (value, record) => {
        return record.delivery === value;
      },
      filterMultiple: false,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => moment(text).format("Do-MMM-YY @ hh:mm a"),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <DeleteTestRegistration record={record} />
        </Space>
      ),
    },
  ];
  return (
    <div style={{ paddingTop: 16 }}>
      {grades ?
        <Row gutter={16} style={{ marginTop: 16 }} justify="center">
          {Object.keys(grades).map(grade => (
            <Col>
              <Card>
                <Statistic
                  title={`Grade ${grade}`}
                  value={grades[grade]}
                />
              </Card>
            </Col>
          ))}
        </Row> :
        null}
        <div style={{padding:8}}/>
      <Table
        columns={columns}
        loading={loading}
        dataSource={[...data].map((x, i) => ({ ...x, key: i }))}
        pagination={{ position: ["none", "bottomCenter"] }}
        size="middle"
        scroll={{ scrollToFirstRowOnChange: true }}
      />
    </div>
  );
}
